/*! _dashboard-right-sidebar.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard right sidebar styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Right Quickview
1. Chat sidebar
=============================================================================
***/

/* ==========================================================================
0. Right Quickview
========================================================================== */

//Right quickview (chat and reminders right sidebar)
.quickview {
    //navigation tabs
    .tabs {
        ul li a {
            height: 80px;
            border-bottom: 2px solid transparent;
            overflow: hidden;

            i {
                font-size: 1.4rem;
                transition: all 0.3s;
                transform: scale(0.8);
            }
        }

        ul li:hover a {
            border-bottom: 2px solid $fade-grey !important;
        }

        ul li.is-active a {
            border-bottom: 2px solid $secondary !important;

            i {
                color: $secondary;
                transform: scale(1);
            }
        }
    }

    //user searchbox
    .searchbox {
        .field {
            width: 100%;
        }

        input {
            width: 100%;
        }
    }

    //title
    .panel-title {
        margin-top: -10px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        &:hover i {
            color: $secondary;
        }

        h3 {
            font-weight: 500;
            color: $title-grey;
        }

        i {
            color: $title-grey;
            cursor: pointer;
        }
    }

    //user list
    .user-list {
        padding: 20px 0;
        max-height: 440px;
        overflow: auto;
        list-style-type: none;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.2);
        }

        //list item
        li {
            display: flex;
            align-items: center;
            padding: 5px 0;
            position: relative;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                background: $light-grey;
            }
        }

        //avatar
        img {
            height: 40px;
            width: 40px;
            border-radius: 50%;

            //margin-left: 15px;
       
        }

        //Status text
        .user-status {
            margin-left: 15px;

            .name {
                color: $blue-grey;
                font-weight: 500;
            }

            .status {
                font-size: 80%;
                color: $muted-grey;
            }
        }

        //Status indicator
        .status-dot {
            width: 12px;
            height: 12px;
            border: 2px solid $white;
            border-radius: 50%;
            background: #999;
            position: absolute;
            top: 6px;
            left: 28px;

            //states
            &.is-online {
                background: $green;
            }

            &.is-busy {
                background: $orange;
            }
        }
    }

    #user-directory {
        padding-bottom: 0 !important;
    }

    //Reminders
    .reminders-list {
        list-style-type: none;
        max-height: 460px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.2);
        }

        //single item
        .reminder {
            position: relative;
            user-select: none;
            margin-bottom: 15px;

            //meta
            .info {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                    display: block;
                }

                i {
                    margin-right: 15px;
                    color: $fade-grey;

                    &.is-high {
                        color: $red;
                    }

                    &.is-low {
                        color: $primary;
                    }
                }

                span i {
                    color: $title-grey !important;
                    font-size: 20px;
                }

                .date {
                    position: relative;
                    color: $muted-grey;
                }
            }

            //reminder content
            .reminder-content {
                font-size: 1rem;
                font-weight: 500;
                color: $grey-6;
                cursor: default;
                padding: 0 20px;
            }

            //reminder actions
            .reminder-controls {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 10px;
                margin-right: 30px;

                i {
                    margin: 0 0 0 10px;
                    font-size: 16px;
                    color: $title-grey;
                    opacity: 0;
                    transition: opacity 0.3s;
                    cursor: pointer;

                    &:hover {
                        color: $secondary;
                    }
                }
            }

            //show reminder controls on hover
            &:hover {
                .reminder-controls i {
                    opacity: 1;
                }
            }
        }
    }

    //Settings list with switches
    .settings-list {
        list-style-type: none;

        //item
        li {
            margin-top: 40px;
        }

        li .setting-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .setting-title {
                font-weight: 500;
                color: $blue-grey;
            }

            .setting-control {
                position: relative;
                top: 4px;
            }
        }

        li .setting-description {
            font-size: 85%;
            margin-top: 15px;
            color: $title-grey;
        }
    }
}

/* ==========================================================================
1. Chat sidebar (slides when you click on a user in the user directory)
========================================================================== */

//Chat Quickview
.is-chat {

    //active state
    &.is-active {
        z-index: 1000 !important;
    }

    .quickview-header {
        .material-icons {
            color: $white-smoke;
        }
    }

    //container
    .message-container {
        position: relative;
        overflow: auto;
        max-height: 650px;
        padding-bottom: 100px;

        //message
        .chat-message {
            padding: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            //avatar
            img {
                height: 35px;
                border-radius: 100px;
            }

            //bubble
            .bubble-wrapper {
                width: 100%;

                .chat-bubble {
                    padding: 10px 20px 10px 20px;
                    font-size: 12px;
                    border: 1px solid $fade-grey;
                    position: relative;
                    top: 30px;
                }

                .timestamp {
                    position: relative;
                    top: 8px;
                    font-size: 11px;
                    font-weight: 500;
                    color: $muted-grey;
                }
            }

            //message from
            &.from {
                .chat-bubble {
                    margin-left: 20px;
                    color: $white;
                    background: $secondary;
                    border-radius: 0 15px 15px 15px;
                }

                .timestamp {
                    right: 10px;
                    float: right;
                }
            }

            //message to
            &.to {
                .chat-bubble {
                    margin-right: 20px;
                    color: $white;
                    background: lighten($secondary, 25%);
                    border: 1px solid $fade-grey;
                    border-radius: 15px 0 15px 15px;
                    text-align: left !important;
                }

                .timestamp {
                    left: 0;
                    top: 23px;
                }
            }
        }

        //custom scrollbar (works only in webkit browsers)
        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.2);
        }

        //messages divider
        .divider {
            display: table;
            white-space: nowrap;
            margin-top: 20px;
            padding: 0 20px;

            .before-divider, .after-divider {
                display: table-cell;
                width: 50%;
                border-bottom: 1px solid $fade-grey;
                transform: translateY(-50%);
                color: $muted-grey;
            }

            .children {
                padding: 0 20px;
                font-size: 14px;
                color: $placeholder;
            }

            &:not(:first-child) {
                margin-top: 40px !important;
            }
        }
    }

    //message textarea wrapper
    .message-input {
        position: absolute;
        bottom: -5px !important;
        width: 100%;

        //textarea
        textarea {
            box-sizing: border-box;
            padding: 18px;
            padding-right: 100px;
            padding-left: 30px;
            width: 100%;
            height: 100%;
            font-family: "intercom-font", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.33;
            white-space: pre;
            white-space: pre-wrap;
            word-wrap: break-word;
            border: none !important;
            resize: none;
            background-color: $smoke-white;

            &:focus {
                outline: none !important;
            }
        }

        //options
        .message-options {
            position: absolute;
            right: 30px;
            top: 0;

            //emoticons
            .emoji-button {
                position: relative;
                float: left;
                display: inline-block;
                cursor: pointer;
                opacity: .7;
                height: 55px;
                width: 30px;

                &:before {
                    background-image: url(https://image.ibb.co/gH13KQ/emoji_icon.png);
                    background-size: 18px 18px;
                    background-repeat: no-repeat;
                    content: " ";
                    width: 18px;
                    height: 18px;
                    left: 5px;
                    border-radius: 50%;
                    position: absolute;
                    top: 20px;
                    background-position: 50%;
                }
            }

            //attach file
            .attach-button {
                position: relative;
                float: left;
                display: inline-block;
                cursor: pointer;
                opacity: .7;
                height: 55px;
                width: 30px;

                &:before {
                    background-image: url(https://image.ibb.co/hSE165/attach_icon.png);
                    background-size: 16px 18px;
                    background-repeat: no-repeat;
                    content: " ";
                    width: 16px;
                    height: 18px;
                    left: 5px;
                    position: absolute;
                    top: 20px;
                    background-position: 50%;
                }
            }
        }
    }
}
