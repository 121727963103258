/*! _dashboard-navbar.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard navbar styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Navbar
1. Quick Search
2. Search Modal
3. Navbar dropdowns
4. Mobile Nav
=============================================================================
***/

/* ==========================================================================
0. Navbar
========================================================================== */

//Pushed navbar state when sidebar is open
.is-pushed {
    transform: translateX(250px);
}

//Dashboard Navbar
nav.dashboard-nav {
    margin-left: 80px;
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    max-width: calc(100% - 80px);
    border-bottom: 1px solid $fade-grey;

    //fluid container
    .container.is-fluid {
        margin: 0;
        height: 65px;
    }

    //Brand
    .navbar-brand {
        margin-left: 0 !important;
        .hamburger-btn {
            margin-left: auto;
        }

        .menu-toggle {
            font-size: 20px;
            color: #666;
            line-height: 48px;
            text-align: center;
            background: transparent;
            display: block;
            width: 24px;
            height: 26px;
            cursor: pointer;
            padding: 0;
            margin: 0 14px;
            transition: opacity 0.4s;
            opacity: 1;
            position: relative;
            top: 2px;

            .icon-box-toggle {
                height: 100%;
                width: 100%;
                background: tranparent;
                position: relative;
                display: block;
                width: 30px;
                height: 30px;

                &.active > span.rotate {
                    /*transform*/
                    -webkit-transform: rotate(90deg);
                    -moz-transform: translate(0px, 0px) rotate(90deg);
                    -ms-transform: translate(0px, 0px) rotate(90deg);
                    -o-transform: translate(0px, 0px) rotate(90deg);
                    transform: translate(0px, 0px) rotate(90deg);
                }

                &.active > span > i.icon-line-center {
                    visibility: hidden;
                    width: 1px;
                    height: 3px;
                    left: 70%;
                }

                &.active > span > i.icon-line-bottom {
                    margin: -2px 0 0 -10px;
                    left: 50%;
                    top: 12px;

                    /*transform*/
                    -webkit-transform: rotate(135deg);
                    -moz-transform: translate(0px, 0px) rotate(135deg);
                    -ms-transform: translate(0px, 0px) rotate(135deg);
                    -o-transform: translate(0px, 0px) rotate(135deg);
                    transform: translate(0px, 0px) rotate(135deg);
                }

                &.active > span > i.icon-line-top {
                    margin: -2px 0 0 -10px;
                    left: 50%;
                    top: 12px;

                    /*transform*/
                    -webkit-transform: rotate(45deg);
                    -moz-transform: translate(0px, 0px) rotate(45deg);
                    -ms-transform: translate(0px, 0px) rotate(45deg);
                    -o-transform: translate(0px, 0px) rotate(45deg);
                    transform: translate(0px, 0px) rotate(45deg);
                }
            }

            .icon-line-center {
                position: absolute;
                width: 20px;
                height: 2px;
                background: $title-grey;
                margin: -1px 0 0 -10px;
                left: 50%;
                top: 11px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            .icon-line-top {
                position: absolute;
                width: 20px;
                height: 2px;
                background: $title-grey;
                margin: -3px 0 0 -10px;
                left: 50%;
                top: 7px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            .icon-line-bottom {
                position: absolute;
                width: 20px;
                height: 2px;
                background: $title-grey;
                margin: 2px 0 0 -10px;
                left: 50%;
                top: 14px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }

    //right menu
    .nav-right {
        padding-right: 0;
    }

    //Quickview trigger
    .chat-button {
        width: 65px !important;
        background: $secondary;
        margin: 0 10px;
        cursor: pointer;
        transition: all 0.4s;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            color: $white !important;
            font-weight: 900 !important;
            font-size: 20px !important;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    //logout button
    .logout-button {
        width: 65px !important;
        cursor: pointer;
        transition: all 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            color: $title-grey !important;
            margin-left: 0 !important;
            top: 0 !important;
            font-weight: 900;
        }

        &:hover {
            background: lighten($fade-grey, 5%);

            i {
                color: $orange !important;
            }
        }
    }

    .navbar-subnav {
        height: 65px;
        display: flex;

        .is-subnav {
            height: 65px;
            width: 65px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 2px solid transparent;
            transition: all 0.3s;

            i {
                color: $placeholder;
                transition: color .3s;
            }

            .sl {
                font-size: 20px;
            }

            &:hover {
                i {
                    color: $muted-grey;
                }
            }

            &.is-active {
                border-bottom-color: $secondary;

                i {
                    color: $secondary;
                }
            }
        }
    }

    //Responsive menu toggle
    .nav-toggle {
        height: 65px !important;
        width: 65px !important;
    }

    //Dropdown menu
    .nav-item.is-drop.nav-icon {
        &:hover {
            border-bottom: none !important;
        }
    }

    //icon menu item
    .nav-icon {
        width: 65px;
        height: 65px;
        display: flex;
        justify-content: center !important;
        align-items: center;

        .icon-container {
            position: relative;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center !important;
            align-items: center;
        }

        i {
            font-size: 18px;
            font-weight: normal;
            color: $title-grey;
            position: relative;
            top: 2px;
            cursor: pointer;
            transition: all 0.5s;

            &.is-active {
                color: $secondary;
            }
        }

        &:hover i {
            color: $secondary;
        }
    }

    //user account
    .account {
        position: relative;
        right: 20px;
        margin-left: 30px;

        .nav-item {
            border-bottom: 1px solid transparent !important;
        }

        //avatar
        img.account-avatar {
            max-height: 40px;
            border-radius: 50px;
            border: 1px solid $light-grey;
        }

        div a {
            color: $medium-grey !important;
        }

        div a i {
            position: relative;
            top: 2px;
            left: 10px;
            font-size: 8px;
            color: $medium-grey;
        }

        .dropOut ul {
            margin-left: 0 !important;
        }
    }

    //nav items
    .navbar-item {
        &.is-tab {
            color: $title-grey;
        }

        &.is-tab:hover {
            border-bottom: 1px solid $primary !important;
            color: $title-grey !important;
        }

        //active state
        &.is-tab.is-active {
            border-bottom: 3px solid $primary !important;
            color: $primary !important;
        }

        &.is-tab.is-active:hover {
            border-bottom: 3px solid $primary !important;
        }

        //reader mode switch
        &.reader-switch .field {
            padding-top: 7px;
        }

        //new messages and notifications indicator
        .new-circle {
            width: 13px;
            height: 13px;
            left: 9px;
            top: -8px;
            position: absolute;
            border-radius: 50px;
            border: 2px solid white;
            background-color: $primary;
        }

        .collaborators {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .face {
                height: 40px;
                width: 40px;
                border-radius: 50%;

                &:not(:first-child) {
                    margin-left: -15px;
                }

                &.is-fake {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $secondary;
                    color: $white;
                    font-weight: 600;
                    font-size: .9rem;
                    border: 3px solid $white;
                }

                img {
                    border: 3px solid $white;
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    max-height: 38px;
                }
            }
        }
    }
}

/* ==========================================================================
1. Quick Search
========================================================================== */

//Navbar quick search
.searchbox {
    //input
    input {
        padding-left: 3rem !important;
        width: 250px;
        border-radius: 100px;
        height: 2.5rem;

        //placeholder
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $placeholder;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: $placeholder;
        }

        &:-ms-input-placeholder { /* IE 10+ */
            color: $placeholder;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $placeholder;
        }

        &:focus {
            box-shadow: inset rgba(143, 160, 241, 0.2) 0 0 0 1px, rgba(213, 220, 247, 0.59) 0 10px 20px;
        }
    }

    //search icon
    .icon.is-left {
        top: 0 !important;
        font-size: 18px;

        i {
            top: -3px;
        }
    }
}

/* ==========================================================================
2. Search Modal
========================================================================== */

//Popup search modal
#search-modal {
    .modal-content {
        padding: 30px 10px 0 10px;

        input {
            color: $white;
            font-size: 2rem;
            padding-right: 50px;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $white;
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: $white;
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: $white;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: $white;
            }
        }

        .material-icons {
            font-size: 3rem;
            right: 0;
            top: 18px;
        }
    }

    //active state
    &.is-active.modal-background {
        margin-left: inherit !important;
        margin-right: inherit !important;
        right: 20px !important;
        top: 20px !important;
        bottom: inherit !important;
    }
}

/* ==========================================================================
3. Navbar dropdowns
========================================================================== */

//Pop dropdowns setup
.drop-pop {
    position: relative;

    //dropdown wrapper
    .drop-wrapper {
        position: absolute;
        top: 66px;
        right: 0;
        border-radius: 3px 3px 5px 5px;
        background: $white;
        min-width: 250px;
        min-height: 100px;
        max-height: 300px;
        display: none;
        transition: opacity 0.3s;
        animation-duration: 0.2s;
        box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.04);

        //inner section
        .drop-inner {
            position: relative;
            background: $white;
            text-align: left;
            border: 1px solid $fade-grey;
            border-radius: 3px 3px 5px 5px;
            width: 100%;
            height: 100%;

            ul {
                padding: 10px 0;
            }

            //manages the small tooltip arrow
            &.has-arrow:after, &.has-arrow:before {
                bottom: 100%;
                right: 7%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &.has-arrow:after {
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: $white;
                border-width: 8px;
                margin-left: -8px;
            }

            &.has-arrow:before {
                border-color: rgba(237, 237, 237, 0);
                border-bottom-color: $fade-grey;
                border-width: 9px;
                margin-left: -9px;
            }
        }

        //active state
        &.is-active {
            opacity: 1;
        }
    }
}

//Notifications dropdown
.notifications-drop {
    width: 320px;
    right: -5px;
    animation: fadeInDown .5s;

    //header
    .notifications-header {
        width: 100%;
        padding: 10px 20px;
        background: $white;
        border-bottom: 1px solid $fade-grey;

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
            color: $muted-grey;
        }
    }

    //body
    .notifications-body {
        background: $white;
        padding: 20px 10px 5px 8px;
        margin-left: -1px;

        .notifications-list {
            padding: 0;
            position: relative;

            //list items
            li:after {
                content: '';
                width: 1.5px;
                left: 20px;
                height: 30px;
                background-color: $title-grey;
                position: absolute;
                opacity: 0.3;
            }

            li:first-child:before {
                content: '';
                width: 1.5px;
                left: 20px;
                top: -24px;
                height: 23px;
                background-color: $title-grey;
                position: absolute;
                opacity: 0.3;
            }

            li {
                margin-bottom: 30px;
                position: relative;
                cursor: pointer;
            }

            li i {
                width: 40px;
                height: 40px;
                display: block;
                text-align: center;
                line-height: 38px;
                font-size: 24px;
                color: $placeholder;

                &.red {
                    color: $red;
                }
            }

            //content
            .notification-content {
                position: absolute;
                margin-left: 50px;
                margin-top: -45px;
                display: flex;
                align-items: center;

                //avatar
                img {
                    width: 45px;
                    height: 45px;
                    max-height: 50px;
                    border-radius: 50%;
                }

                //text
                .notification-text {
                    .text {
                        font-size: 1rem;
                        margin-left: 10px;
                        color: $muted-grey;

                        b {
                            color: $blue-grey;
                        }
                    }

                    .timestamp {
                        font-size: 85%;
                        margin-left: 10px;
                        color: $title-grey;
                    }
                }
            }
        }
    }
}

//Messages dropdown
.emails-drop {
    width: 320px;
    right: -5px;
    animation: fadeInDown .5s;

    //dropdown header
    .emails-header {
        width: 100%;
        padding: 10px 20px;
        background: $white;
        border-bottom: 1px solid $fade-grey;

        h3 {
            font-size: 1.3rem;
            font-weight: 600;
            color: $title-grey;
        }
    }

    //dropdown body
    .emails-body {
        background: $white;
        padding: 20px 10px 5px 7px;
        margin-left: -1px;

        //item list
        .emails-list {
            padding: 0;
            position: relative;

            li:after {
                content: '';
                width: 1.5px;
                left: 24px;
                height: 18px;
                bottom: -24px;
                background-color: $title-grey;
                position: absolute;
                opacity: 0.3;
            }

            li:first-child:before {
                content: '';
                width: 1.5px;
                left: 24px;
                top: -24px;
                height: 18px;
                background-color: $title-grey;
                position: absolute;
                opacity: 0.3;
            }

            li {
                margin-bottom: 30px;
                position: relative;
                cursor: pointer;
            }

            //image avatar and initials avatar
            li img, li .fake-avatar {
                width: 40px;
                height: 40px;
                min-height: 40px;
                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 38px;
                font-size: 16px;
                font-weight: 600;
                color: $white !important;
                margin-left: 4px;
                background: $muted-grey;

                &.is-primary {
                    background: $primary;
                }

                &.is-secondary {
                    background: $secondary;
                }
            }

            //item content
            .email-content {
                position: absolute;
                margin-left: 50px;
                margin-top: -38px;
                display: flex;
                align-items: center;

                .email-text {
                    .text {
                        font-size: 90%;
                        font-weight: 500;
                        margin-left: 10px;
                        color: $blue-grey;

                        &.is-read {
                            opacity: 0.7;
                            color: $title-grey;
                            font-weight: 400;
                        }
                    }

                    .timestamp {
                        font-size: 70%;
                        margin-right: 10px;
                        color: $blue-grey;
                        font-weight: 400;
                        float: right;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
4. Mobile Nav
========================================================================== */

.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 60px;
    width: 100%;
    max-width: calc(100% - 60px);
    height: 50px;
    background: $white;
    border-bottom: 1px solid $fade-grey;
    transform: translateY(0);
    transition: all .3s;
    z-index: 29;

    &.is-active {
        transform: translateY(65px);
    }

    &.is-fullwidth {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
    }

    .mobile-nav-inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        .mobile-item {
            position: relative;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 18px;
                color: $title-grey;
            }

            .new-circle {
                width: 10px;
                height: 10px;
                right: 14px;
                top: 11px;
                position: absolute;
                border-radius: 50px;
                border: 2px solid $smoke-white;
                background-color: $primary;
            }
        }
    }
}
